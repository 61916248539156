import { AuthContext } from "components/contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "./useApi";
import useOcr from "./useOcr";
import { toastMessage } from "util/common";
import moment from "moment";

const DEFAULT_VALUES = {
	license_url: "",
	previewUrl: "",
	license_expiry: null,
	license_start: null,
	license_number: "",
	license_doctor_name: "",
	is_medical_condition: false,
	medical_condition: null,
};

function convertToISODate(dateString) {
	return moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
}

const useLicense = () => {
	const api = useApi();
	const {
		updatedFields: updatedLicenseFields,
		updateField,
		testDate,
	} = useOcr();
	const [mainLicense, setMainLicense] = useState(null);
	const [loading, setLoading] = useState(false);
	const { claims, authenticated } = useContext(AuthContext);
	const today = moment().format("YYYY-MM-DD");

	const licenseForm = useForm({
		defaultValues: {
			...DEFAULT_VALUES,
			is_medical_condition: claims?.is_medical_condition || false,
			medical_condition: claims?.medical_condition,
		},
	});

	const apiLoadLicense = async () => {
		setLoading(true);
		try {
			const res = await api.getUserLicenses();
			let mainLicense = null;
			if (res.ok) {
				mainLicense = res.data.data.find(license => license.main);
				setMainLicense(mainLicense);
				licenseForm.reset(mainLicense);
			}
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	const apiCreateLicense = async (data = null) => {
		if (
			!!data?.is_medical_condition ||
			!!licenseForm.getValues("is_medical_condition")
		) {
			return false;
		}

		setLoading(true);
		const param = data ?? {
			license_number: licenseForm.getValues("license_number"),
			license_doctor_name: licenseForm.getValues("license_doctor_name"),
			license_url: licenseForm.getValues("license_url"),
			license_start: licenseForm.getValues("license_start"),
			license_expiry: licenseForm.getValues("license_expiry"),
			main: true,
		};
		const res = await api.createUserLicense(param);
		if (!res.ok) {
			toastMessage("שגיאה בשמירת הרישיון", "error");
			return;
		}
		toastMessage("הרישיון הועלה בהצלחה", "success");
		setLoading(false);
	};

	const apiEditLicense = async data => {
		if (
			!!data?.is_medical_condition ||
			!!licenseForm.getValues("is_medical_condition")
		) {
			return false;
		}

		setLoading(true);
		const param = data ?? {
			license_number: licenseForm.getValues("license_number"),
			license_doctor_name: licenseForm.getValues("license_doctor_name"),
			license_url: licenseForm.getValues("license_url"),
			license_start: licenseForm.getValues("license_start"),
			license_expiry: licenseForm.getValues("license_expiry"),
		};

		const res = await api.editUserLicense(data.id, param);
		if (!res.ok) {
			toastMessage("שגיאה בעדכון רישיון", "error");
			setLoading(false);
			return;
		}
		toastMessage("הרישיון עודכן בהצלחה", "success");
		setLoading(false);
	};

	const apiDeleteLicense = async licenseId => {
		setLoading(true);
		const res = await api.softDeleteUserLicense(licenseId);
		if (res.ok) {
			toastMessage("הרישיון נמחק בהצלחה", "success");
		} else {
			toastMessage("שגיאה במחיקת הרישיון", "error");
		}
		setLoading(false);
	};

	const handleLicenseSave = async (data = licenseForm.watch()) => {
		if (
			!!mainLicense?.license_url &&
			!!data?.license_url &&
			mainLicense.license_url === data.license_url
		) {
			apiEditLicense(data);
		} else {
			apiCreateLicense({ ...data, main: true });
		}
	};

	const licenseNotificationToggle = async val => {
		const params = { notifications_enabled: val };
		const res = await api.notifyExpiredLicense(params);
		if (res.ok) {
			toastMessage(res.data.message, "success");
		} else {
			toastMessage("שגיאה בשמירת ההגדרה", "error");
		}
	};

	const insertLicenseOcrData = data => {
		const constructedData = constructLicenseOcrData(data);

		if (constructedData?.number) {
			updateField("license_number", constructedData?.number, licenseForm);
		} else {
			licenseForm.setValue("license_number", null);
		}
		if (constructedData?.license_doctor_name) {
			updateField(
				"license_doctor_name",
				constructedData?.license_doctor_name,
				licenseForm,
			);
		} else {
			licenseForm.setValue("license_doctor_name", null);
		}

		if (constructedData.license_start <= today) {
			updateField("license_start", constructedData.license_start, licenseForm);
		} else {
			licenseForm.setValue("license_start", null);
		}
		if (constructedData.license_expiry >= today) {
			updateField(
				"license_expiry",
				constructedData.license_expiry,
				licenseForm,
			);
		} else {
			licenseForm.setValue("license_expiry", null);
		}
	};

	const constructLicenseOcrData = data => {
		const excludedData = data?.data?.[0]?.user_license ?? [];

		let fromDate = excludedData?.license_dates?.from ?? null;
		let toDate = excludedData?.license_dates?.to ?? null;

		let licenseNumber = excludedData?.license?.replace(/\D/g, "") ?? "";
		let doctorName =
			excludedData?.doctor_info?.replace(/[^a-zA-Zא-ת\s"]/g, "")?.trim() ?? "";

		if (!!fromDate && testDate(fromDate)) {
			fromDate = convertToISODate(fromDate);
		} else {
			fromDate = null;
		}

		if (!!toDate && testDate(toDate)) {
			toDate = convertToISODate(toDate);
		} else {
			toDate = null;
		}

		return {
			license_number: licenseNumber,
			license_doctor_name: doctorName,
			license_start: fromDate,
			license_expiry: toDate,
		};
	};

	useEffect(() => {
		if (authenticated) {
			apiLoadLicense();
		}
	}, [authenticated]);

	return {
		loading,
		setLoading,
		mainLicense,
		apiDeleteLicense,
		licenseForm,
		apiCreateLicense,
		insertLicenseOcrData,
		handleLicenseSave,
		apiLoadLicense,
		updatedLicenseFields,
		apiEditLicense,
		licenseNotificationToggle,
		//
		constructLicenseOcrData,
	};
};
export default useLicense;
